
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import { MrUiKitButton, MrUiKitModal } from "mr-ui-kit";
import { required } from "@vuelidate/validators";
import { email } from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import { Dealer, UserRole } from "@/interfaces";
import DealerServices from "@/services/DealerServices";
import * as _ from "lodash";
import { useStore } from "vuex";

export default defineComponent({
  name: "AddDealer",
  emits: ["submitForm", "close"],
  components: {
    MrUiKitButton,
    MrUiKitModal,
  },
  props: {
    dealer: {
      type: Object as PropType<Dealer>,
      default: null,
    },
    mode: {
      default: "Aggiungi",
    },
  },

  setup(props, { emit }) {
    const isMounted = ref(false);

    const store = useStore();

    const dealer = ref(_.cloneDeep(props.dealer));

    const formFields = reactive([
      {
        component: "MrUiKitInput",
        name: "registeredName",
        props: {
          label: "Ragione Sociale",
        },
        rules: {
          required: helpers.withMessage("Campo Obbligatorio", required),
        },
      },
      {
        component: "MrUiKitInput",
        name: "email",
        props: {
          label: "Email",
        },
        rules: {
          required: helpers.withMessage("Campo Obbligatorio", required),
          email: helpers.withMessage("Insersci una email valida", email),
        },
      },
      {
        component: "MrUiKitInput",
        name: "operatorCode",
        props: {
          label: "Codice operatore Economico",
        },
        rules: {
          required: helpers.withMessage("Campo Obbligatorio", required),
        },
      },
      {
        component: "MrUiKitInput",
        name: "address",
        props: {
          label: "Indirizzo",
        },
        rules: {
          required: helpers.withMessage("Campo Obbligatorio", required),
        },
      },
      {
        component: "MrUiKitInput",
        name: "city",
        props: {
          label: "Città",
        },
        rules: {
          required: helpers.withMessage("Campo Obbligatorio", required),
        },
      },
      {
        component: "MrUiKitToggle",
        name: "role",
        props: {
          label: "Concessionario",
          noBoolean: true,
          items: [
            {
              label: UserRole.Dealer,
              value: true,
            },
            {
              label: UserRole.NoDealer,
              value: false,
            },
          ],
        },
      },
      {
        component: "MrUiKitToggle",
        name: "active",
        props: {
          label: "Attivo",
        },
      },
    ]);

    const form =
      dealer.value ||
      reactive<Dealer>({
        id: null,
        sendAdminEmail: null,
        email: "",
        role: UserRole.NoDealer,
        registeredName: "",
        operatorCode: "",
        address: "",
        city: "",
        active: false,
      });

    onMounted(() => {
      isMounted.value = true;
    });

    const modifyDealer = async () => {
      await DealerServices.modifyDealer(form);
    };

    const createDealer = async () => {
      await DealerServices.createDealer(form);
    };

    const onSubmit = async () => {
      try {
        dealer.value ? await modifyDealer() : await createDealer();
        store.commit("setAlert", {
          type: "success",
          message: dealer.value
            ? "Modifica effettuata con successo"
            : "Esercente creato con successo",
        });
        emit("submitForm", "");
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: "Ops... c'è stato un errore.",
        });
      }
    };

    const onClose = () => {
      emit("close");
    };

    return {
      form,
      formFields,
      isMounted,
      onSubmit,
      onClose,
    };
  },
});
