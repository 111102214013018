import { GeneralFile } from "@/interfaces";
import { Dealer } from "@/interfaces/dealer";
import axios, { AxiosResponse } from "axios";
import FilterService from "./FilterService";

class DealerService {
  async createDealer(dealer: Dealer): Promise<any> {
    const { data }: AxiosResponse<any> = await axios.post(
      "/admin/users",
      dealer
    );
    return data;
  }

  async modifyDealer(dealer: Dealer): Promise<any> {
    const { data }: AxiosResponse<any> = await axios.patch(
      "/admin/users/" + dealer.id,
      dealer
    );
    return data;
  }

  async getAllDealers(filter: any): Promise<Dealer[]> {
    const parsed = FilterService.parseFilter(filter);
    const { data }: AxiosResponse<Dealer[]> = await axios.get(
      "/admin/users" + "?" + parsed
    );
    return data;
  }

  async countDealers(filter: any): Promise<any> {
    const parsed = FilterService.parseFilter(filter);
    const { data }: AxiosResponse<any> = await axios.get(
      "/admin/users/count" + "?" + parsed
    );
    return data.count;
  }

  async getSingleDealer(id: number): Promise<Dealer> {
    const { data }: AxiosResponse<Dealer> = await axios.get(
      "/admin/users/" + id
    );
    return data;
  }

  async deleteSingleDealer(id: number): Promise<Dealer> {
    const { data }: AxiosResponse<Dealer> = await axios.delete(
      "/admin/users/" + id
    );
    return data;
  }
}

export default new DealerService();
