
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { MrUiKitTable, MrUiKitButton, MrUiKitPagination } from "mr-ui-kit";
import AddDealer from "@/components/Backoffice/modals/AddEditDealer.vue";
import DealerServices from "@/services/DealerServices";
import DeleteModal from "@/components/Backoffice/modals/DeleteModal.vue";
import { Dealer, PaginationModel } from "@/interfaces";
import FileLink from "@/components/UI/FileLink.vue";
import { useStore } from "vuex";
import * as _ from "lodash";

export default defineComponent({
  name: "DealersList",
  components: {
    MrUiKitTable,
    MrUiKitButton,
    MrUiKitPagination,
    AddDealer,
    FileLink,
    DeleteModal,
  },
  setup() {
    const isMounted = ref(false);
    const isLoading = ref(false);
    const addModalOpened = ref(false);
    const deleteText = ref("");
    const deleteModalOpened = ref(false);
    const dealerTable = ref<Dealer[]>([]);
    const selectedDealer = ref<Dealer | null>(null);
    const totalDealer = ref(0);
    const store = useStore();
    const limit = ref(10);

    const filters = reactive({
      id: "",
      operatorCode: "",
      email: "",
      registeredName: "",
      active: null,
    });

    onMounted(async () => {
      await onInit();
      isMounted.value = true;
    });

    const onInit = async () => {
      pagination.current = 1;

      let filterClone = _.cloneDeep(filters) as any;
      filterClone.active = filterClone.active
        ? (filterClone.active.value as boolean)
        : null;

      totalDealer.value = await DealerServices.countDealers(filterClone);
      pagination.count = totalDealer.value;
      await onLoad(0);
    };

    const onLoad = async (offset: number) => {
      let filterClone = _.cloneDeep(filters) as any;
      filterClone.active = filterClone.active
        ? (filterClone.active.value as boolean)
        : null;
      if (!isLoading.value) {
        isLoading.value = true;

        dealerTable.value = await DealerServices.getAllDealers({
          ...filterClone,
          offset,
          limit: limit.value,
        });

        isLoading.value = false;
      }
    };

    watch(filters, (newVal) => {
      onInit();
    });

    const pagination = reactive<PaginationModel>({
      current: 1,
      count: 0,
      limit: limit.value,
    });

    const dealerColumns = ref([
      { label: "Id", field: "id" },
      { label: "Ragione Sociale", field: "registeredName" },
      { label: "Stato", field: "active" },
      { label: "COE", field: "operatorCode" },
      { label: "email", field: "email" },
      { label: "", field: "actions" },
    ]);

    const toggleModal = async (refresh?: boolean, dealer?: Dealer) => {
      selectedDealer.value = dealer ?? null;
      if (refresh) {
        onLoad(0);
      }

      addModalOpened.value = !addModalOpened.value;
    };

    const deleteModal = (dealer: Dealer) => {
      deleteText.value =
        "Attenzione: Elininando l'esercente ''" +
        dealer.registeredName +
        "'' non sarai più in grado di visionare le richieste effettuate sino da oggi. Sicuro di voler continuare?";
      selectedDealer.value = dealer;
      deleteModalOpened.value = !deleteModalOpened.value;
    };

    const deleteDealer = async (dealer: Dealer) => {
      try {
        await DealerServices.deleteSingleDealer(dealer.id!);

        store.commit("setAlert", {
          type: "success",
          message: "Esercente eliminato con successo",
        });
        onLoad(0);
        deleteModalOpened.value = false;
      } catch (err: any) {
        store.commit("setAlert", {
          type: "danger",
          message: "Ops... c'è stato un errore.",
        });
      }
    };

    return {
      isMounted,
      toggleModal,
      deleteDealer,
      deleteModal,
      deleteModalOpened,
      selectedDealer,
      addModalOpened,
      dealerTable,
      dealerColumns,
      pagination,
      onLoad,
      filters,
      deleteText,
      activeItems: [
        {
          label: "Attivo",
          value: true,
        },
        {
          label: "Offline",
          value: false,
        },
      ],
      totalDealer,
      limit,
    };
  },
});
